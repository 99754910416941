// tslint:disable:comment-format binary-expression-operand-order max-line-length
// tslint:disable:no-bitwise prefer-template cyclomatic-complexity
// tslint:disable:no-shadowed-variable switch-default prefer-const
// tslint:disable:one-variable-per-declaration newline-before-return

import { LocaleData } from '../locale/locale.class';

//! moment.js locale configuration
//! locale : Albanian [sq]
//! author : Agon Cecelia : https://github.com/agoncecelia

export const sqLocale: LocaleData = {
  abbr: 'sq',
  months : 'Janar_Shkurt_Mars_Prill_Maj_Qershor_Korrik_Gusht_Shtator_Tetor_NÃ«ntor_Dhjetor'.split('_'),
  monthsShort : 'Jan_Shk_Mar_Pri_Maj_Qer_Kor_Gus_Sht_Tet_NÃ«n_Dhj'.split('_'),
  weekdays : 'E DielÃ«_E HÃ«nÃ«_E MartÃ«_E MÃ«rkurÃ«_E Enjte_E Premte_E ShtunÃ«'.split('_'),
  weekdaysShort : 'Die_HÃ«n_Mar_MÃ«r_Enj_Pre_Sht'.split('_'),
  weekdaysMin : 'Di_He_Ma_Me_En_Pr_Sh'.split('_'),
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss',
    L : 'DD/MM/YYYY',
    LL : 'D MMMM YYYY',
    LLL : 'D MMMM YYYY HH:mm',
    LLLL : 'dddd, D MMMM YYYY HH:mm'
  },
  calendar : {
    sameDay : '[Sot nÃ«] LT',
    nextDay : '[NesÃ«r nÃ«] LT',
    nextWeek : 'dddd [nÃ«] LT',
    lastDay : '[Dje nÃ«] LT',
    lastWeek : 'dddd [e kaluar nÃ«] LT',
    sameElse : 'L'
  },
  relativeTime : {
    future : 'nÃ« %s',
    past : 'para %sve',
    s : 'disa sekonda',
    ss : '%d sekonda',
    m : 'njÃ« minut',
    mm : '%d minuta',
    h : 'njÃ« orÃ«',
    hh : '%d orÃ«',
    d : 'njÃ« ditÃ«',
    dd : '%d ditÃ«',
    M : 'njÃ« muaj',
    MM : '%d muaj',
    y : 'njÃ« vit',
    yy : '%d vite'
  },
  dayOfMonthOrdinalParse: /\d{1,2}\./, // need clarification
  ordinal : '%d.', // need clarification
  week : {
    dow : 1, // Monday is the first day of the week.
    doy : 4  // The week that contains Jan 4th is the first week of the year.
  }
};

